@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", serif; /* Example font for headings */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

a:hover {
  color: black !important;
}

p,
div,
span {
  font-family: "Inter", sans-serif; /* Example font, adjust to your chosen font */
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.fade-out {
  opacity: 0;
  transition: opacity 8s ease-out;
}
